.app__barber-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.app__barber-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__barber-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__barber-sign {
    width: 100%;
    margin-top: 1rem;
}

.app__barber-sign p:first-child {
    font-family: var(--font-cursive);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-grey);
}

.app__barber-sign img {
    width: 300px;
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__barber-sign img {
        width: 400px;
    }
}

@media screen and (max-width: 450px) {
    .app__barber-sign img {
        width: 80%;
    }
}