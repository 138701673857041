.app__sservices {
    flex-direction: column;
    background: var(--color-black);
}

.app__sservices-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__sservices-service {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__sservices-service_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 40px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}

.app__sservices-service_cuts,
.app__sservices-service_caveats {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__sservices-service_img {
    width: 18em;
    margin: 0 2rem;
}

.app__sservices-service_img img {
    width: 100%;
    margin: auto;
}

.app__sservices_service_items {
    display: flex;
    flex-direction: column;
    cursor: default;
    margin: 2rem 0;
    width: 100%;
    max-width: 600px;
}

@media screen and (min-width: 2000px) {
    .app__sservices-service_img {
        width: 500px;
    }

}

@media screen and (max-width: 1150px) {
    .app__sservices-service {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__sservices-service_img {
        margin: 3rem 0;
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .app__sservices-service_img {
        /* width: 100%; */
        display: none;
    }

    .app__sservices-service_heading {
        font-size: 35px;
        line-height: 0px;
    }
}